import ProjectGallery from "../../components/ProjectGallery/ProjectGallery";

const ProjectsPage = () => {

  return (
    <>
      <ProjectGallery />
    </>
  );
};

export default ProjectsPage;
